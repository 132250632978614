import React from 'react';
import { Link } from "react-router-dom";
import { HomeCardsData } from './HomeData';

export default function HomeCards() {
  return (
    <div className="mt-5">
        <Link to="/cards">
            <a className='' href="/cards">
                <div className="row m-0 d-flex justify-content-between">
                    <div className="p-0 m-2 pt-2 float-left col-sm-12 col-md-9 col-xl-9 ">
                        <h2>Um pouco de arte...</h2>
                    </div>    
                </div>
                <div className="row m-0 d-flex justify-content-between">
                    {HomeCardsData.map(data => {
                        return (
                            <div className=" p-0 pt-2 bg-body rounded float-left col-sm-12 col-md-4 col-xl-4 ">
                                <p>{data.title}</p>
                                    <img src={data.src} alt={data.alt} width="100%" />
                                <p>
                                </p>
                            </div>
                        );
                    })}
                </div>
            </a>
        </Link>
    </div>
  )
}
