import {Container, Row, Col} from 'react-bootstrap';
import React from 'react';
import './Breadcrumb.css';

export default function Breadcrumb(props) {
  return (
    <div className='breadcrumb'>
      <Container>
        <Row>
          <Col>
            <h1>{props.title}</h1>
            <p>{props.desc}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
