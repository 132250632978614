export const BreadCrumbData = [
    {
        title: "Vida a Favor do Mundo e Tecnologia a Serviço da Vida",
        desc: "www.sitedoed.com.br",
    }
];

export const HomeVideos = [
    {
        id: "1",
        title: "Aconteça o que acontecer, seja o melhor que puder!",
        iframe: "https://www.youtube.com/embed/gFHKQhRcY08",
    },
];

export const HomeCardsData = [
    {
        id: "1",
        title: "Cortesia",
        src: require("../assets/images/cards/cortesia.jpg"),
        alt: "Cortesia",
        text: "A cortesia não custa nada, mas vale muito! Deixo aqui um pouco do meu carinho pra você",
    },
    {
        id: "2",
        title: "Brilha",
        src: require("../assets/images/cards/brilha.jpg"),
        alt: "Brilha",
        text: "Nem mesmo as piores lágrimas podem ofuscar o bilho dos teus olhos. Respira fundo e brilha forte minha Estrela, porque o mundo precisa de Luz",
    },
    {
        id: "3",
        title: "Razões",
        src: require("../assets/images/cards/razoes.jpg"),
        alt: "Razões",
        text: "Nascer, encontrar o útero materno,entr milhões de possibilidades, que também tentavam ganhar a luz do dia, foi a nossa primeira vitória! De lá ra cá, milhares de outras batalhas ocorreram, nessa enorme jornada chamada: Vida. E muitas outras virão, porque somos, acima de tudo, mudança, transformação! Frutos de um coração que semeia, uma vontade que luta e uma razão que sempre encontra outras razões pra seguir em frente!",
    },
];