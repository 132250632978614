import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Banner from './Banner';
import Breadcrumb from './Breadcrumb';
import MainNav from './MainNav';
import SocialNetworks from './SocialNetworks';
import { BreadCrumbData } from './HomeData';
import HomeMainVideo from './HomeMainVideo';
import HomeCards from './HomeCards';
import HomeShorts from './HomeShorts';
import Footer from './Footer';

export default function Home() {
  return (
    <div>
        <MainNav />
        <Banner />
        <SocialNetworks />
        {BreadCrumbData.map(data => {
            return(
                <Breadcrumb title={data.title} desc={data.desc} />
            );
        })}

        <HomeMainVideo />
        <HomeCards />
        <HomeShorts />
        <Footer />
      
    </div>
  )
}
