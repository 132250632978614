import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from "react";
import './PortfolioPage.css';
import { mainCiadoFisico } from './PortfolioData';
import { sidebarCiadoFisico } from './PortfolioData';
import { Link } from 'react-router-dom';
import MainNav from '../MainNav';
import Footer from '../Footer';
import SocialNetworks from '../SocialNetworks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

export default function CiadoFisico() {
    return (
    <div>
        <MainNav />
        <div className="margin-top">
            <Box>  
            <Container sx={{mt: 20}}>
                <Grid container spacing={2}>
                    <Grid item xs={11} sm={8} lg={8}>
                    {mainCiadoFisico.map((data, key) => {
                        return(
                            <>
                                <h2>{data.title}</h2>
                                <p>
                                    {data.p1}
                                </p>
                                <img class="mt-3 mb-5" src={data.img1} width="100%" alt={data.alt1}/>
                                <p>
                                    {data.p2}
                                </p>
                                <p>
                                    {data.p3}
                                </p>
                                <p>
                                    <span className='black_font'><strong>Visite:</strong>
                                        <a href={data.link} target='_blank' rel="noreferrer"> {data.appName} 
                                            <FontAwesomeIcon icon={faCaretRight} />
                                        </a>
                                    </span>
                                </p>
                                <br></br>
                                <h3>{data.subtittle}</h3>
                                <p>
                                    {data.p4}
                                </p>
                            </>
                        );
                        })}
                    </Grid>    
                    <Grid item xs={11} sm={4} lg={4}>
                        {sidebarCiadoFisico.map((data, key) => {
                            return(
                                <>
                                    <h3>{data.sidebarTitle}</h3>
                                    <img src={data.imgFull} width="100%" alt={data.alt} />
                                    <Grid sx={{ mt:5 }}>
                                        <p>
                                            <h4>Outros Projetos</h4>
                                        </p>
                                        <ul>
                                            <li>
                                                <Link to={data.link1}>{data.link1Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link2}>{data.link2Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link3}>{data.link3Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link4}>{data.link4Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link5}>{data.link5Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link6}>{data.link6Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link7}>{data.link7Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link8}>{data.link8Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link9}>{data.link9Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link10}>{data.link10Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link11}>{data.link11Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link12}>{data.link12Page}</Link>
                                            </li>
                                            <li>
                                                <Link to={data.link13}>{data.link13Page}</Link>
                                            </li>
                                        </ul>
                                    </Grid>    
                                </>
                            );
                        })}
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </div>
        <SocialNetworks />
        <Footer/>
    </div>
  )
}
