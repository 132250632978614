export const BreadCrumbData = [
    {
        title: "Cards",
        desc: "Mr. Ed, Plural de um Jeito Único!",
    }
];

export const CardsData = [
    {
        id: "1",
        title: "Cortesia",
        src: require("../assets/images/cards/cortesia.jpg"),
        alt: "Cortesia",
        text: "A cortesia não custa nada, mas vale muito! Deixo aqui um pouco do meu carinho pra você",
    },
    {
        id: "2",
        title: "Brilha",
        src: require("../assets/images/cards/brilha.jpg"),
        alt: "Brilha",
        text: "Nem mesmo as piores lágrimas podem ofuscar o bilho dos teus olhos. Respira fundo e brilha forte minha Estrela, porque o mundo precisa de Luz",
    },
    {
        id: "3",
        title: "Razões",
        src: require("../assets/images/cards/razoes.jpg"),
        alt: "Razões",
        text: "Nascer, encontrar o útero materno,entr milhões de possibilidades, que também tentavam ganhar a luz do dia, foi a nossa primeira vitória! De lá ra cá, milhares de outras batalhas ocorreram, nessa enorme jornada chamada: Vida. E muitas outras virão, porque somos, acima de tudo, mudança, transformação! Frutos de um coração que semeia, uma vontade que luta e uma razão que sempre encontra outras razões pra seguir em frente!",
    },
    {
        id: "4",
        title: "Amanhã",
        src: require("../assets/images/cards/amanha.jpg"),
        alt: "Amanhã",
        text: "Olhe pra dentro de ti e veja quem te faz acreditar no amanhã...",
    },
    {
        id: "5",
        title: "Aparências",
        src: require("../assets/images/cards/aparencias.jpg"),
        alt: "Aparências",
        text: "Pra quem olha, pode até parecer pequeno mas, pra quem sente, o amor é maior que tudo!",
    },
    {
        id: "6",
        title: "Beleza",
        src: require("../assets/images/cards/beleza.jpg"),
        alt: "Beleza",
        text: "Teus cabelos mudarão, tua pele mudará. Os teus sonhos mudarão, mas o brilhos dos teus olhos te acompanhará por todo a vida. Porque a beleza do mundo inteiro depende do brilho desse olhar",
    },
    {
        id: "7",
        title: "Esperança",
        src: require("../assets/images/cards/botaodeesperanca.jpg"),
        alt: "Esperança",
        text: "Olhe pra dentro de ti e veja quem te faz acreditar no amanhã...",
    },
    {
        id: "8",
        title: "Detalhes",
        src: require("../assets/images/cards/detalhes.jpg"),
        alt: "Detalhes",
        text: "Pra alguns, tudo parece impossível... Pra mim, impossível é, apenas, não lutar por um sonho!",
    },
    {
        id: "9",
        title: "Confie",
        src: require("../assets/images/cards/confie.jpg"),
        alt: "Confie",
        text: "Olhe pra dentro de ti e veja quem te faz acreditar no amanhã...",
    },
    {
        id: "10",
        title: "Um sol que brilha por você",
        src: require("../assets/images/cards/umsolquebrilhaporvc.jpg"),
        alt: "Um sol que brilha por você",
        text: "Olha, existe um sol que brilha, todos os dias, por você.",
    },
    {
        id: "11",
        title: "Sempre que você precisar",
        src: require("../assets/images/cards/mao.jpg"),
        alt: "Mão",
        text: "Eu não posso te dar o mundo inteiro, mas posso te dar a mão sempre que você precisar",
    },
    {
        id: "12",
        title: "Pode ter certeza",
        src: require("../assets/images/cards/sempre.jpg"),
        alt: "Certeza",
        text: "A chuva passa, o Sol e as Estrelas ficam. O Céu sempre volta a brilhar!!!",
    },
];