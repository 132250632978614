import React, { useState, useEffect } from "react";
import './MainNav.css';
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";


export default function MainNav() {
  
    //displaying/hidding navBar
    const [show, setShow] = useState();
    const navbarControl = () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", navbarControl);
      return () => window.removeEventListener("scroll", navbarControl);
    }, []);

    return (
    <div>
      <div className={`main_nav ${show && "main_nav_bg"}`}>
        <Container>
          <Navbar expand="lg" fixed="top" >
            <Container>
              <Navbar.Brand className="navbar-spa " href="#">
                <a className="navbar-default" href="/">
                  <span className="logo">Site do Ed</span>
                </a>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="nav_btns" >
                <Nav className="ms-auto" activeKey="/home">
                  <Nav.Item>
                    <Nav.Link href="/home">
                      <a className='btn_menu' href="/">
                        Home
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/bio">
                      <a className='btn_menu' href="/bio">
                        Bio
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/portfolio">
                      <a className='btn_menu' href="/portfolio">
                        Portfolio
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/cards">
                      <a className='btn_menu' href="/cards">
                        Cards
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                  <NavDropdown title="Videos" id="videos">
                    <NavDropdown.Item eventKey="4.1" className="drop-down" href="/videos/musica">Música</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" className="drop-down" href="/videos/cronicas">Crônicas</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.3" className="drop-down" href="/videos/tecnologia">TIC - Tecnologia e Afins</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
    </div>
  )
}
