import React from 'react';
import { HomeVideos } from './HomeData';

export default function HomeMainVideo() {
  return (
    <div>
        <div className="row m-0 justify-content-md-center">
            <div className=" p-0 m-2 pt-2 bg-body rounded float-left col-sm-10 col-md-10 col-xl-10 ">
                {HomeVideos.map(data => {
                    return (
                        <div>
                            <div className='p-3'>
                                <h3>Aconteça o que acontecer, seja o melhor que puder</h3>
                                <br></br>
                                <p> Eu gosto de ver o sorriso estampado, pela felicidade, no rosto das pessoas. E, de vez em quando, também curto uma foto cheia de dentes, daquelas que a  gente tira falando x pra parecer mais simpático. Gosto muito quando alguém viaja, quando alguém se forma, quando alguém casa, ou compra uma casa, entre um monte de outras coisas que eu gosto, de graça. </p>
                                <p>Simplesmente porque gosto de gente, gosto de bicho, gosto de planta, gosto de gostar e gosto da vida.</p>
                                <p>Não que eu não tenha problemas e preocupações, contas a pagar e aquele tipo de dor que a gente aprende a suportar,  mas quando se lembra enche os olhos de lágrimas.</p>
                                <p>É que eu preciso fazer algo diferente.</p>
                                <p>De vez em quando,  quando todo mundo duvida de mim.</p>
                                <p>Eu preciso me olhar no espelho e dizer:</p>
                                <p>SEGUE EM FRENTE CARA!</p>
                                <p>Mas não deixe de olhar pra trás,  porque tem muita gente que não tá aqui agora.Mas sempre se importou contigo.</p>
                                <p>Nunca deixe de acreditar que o amanhã  há de ser melhor que hoje! E não deixe de lutar, porque nada acontece por acaso.</p>
                                <p>Talvez, quem te desanima, quem te põe pra baixo, não saiba ou queira ignorar, que dentro de cada peito existe uma criança precisando de carinho,  pra não virar um adulto amargo. Precisando de esperança pra conquistar seus sonhos. E precisando de sorrisos, muitos sorrisos pra ser Feliz!</p>
                                <p>Por isso,  continue dando o melhor de si. Continue sendo o melhor que puder. Porque a tua vida depende muito mais disso do que da opinião dos outros.</p>
                                <p>Agora, pra você que me acompanha: Muito obrigado pela atenção!</p>
                                <p>Um grande abraço!</p>
                                <p>Fica com Deus e até próxima!</p>
                            </div>
                            <div>
                            <br></br>

                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
  )
}
